import React from 'react'

interface Props {}

function SearchDomain(props: Props) {
  const {} = props

  return <div>Hello World!!!</div>
}

export default SearchDomain
